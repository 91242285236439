import { Icon } from "@chakra-ui/react"
import React from "react"

export function Cloud(props) {
  return (
    <Icon viewBox="0 0 24 24" w="15px" h="15px" {...props}>
      <path
        d="M17.0001 21H7.00012C5.58335 21.0009 4.21197 20.5004 3.12881 19.5871C2.04565 18.6739 1.32059 17.4068 1.08202 16.0103C0.843459 14.6137 1.10678 13.1778 1.82535 11.9568C2.54393 10.7358 3.6714 9.80844 5.00812 9.33896C4.96284 8.39262 5.11013 7.44694 5.44108 6.5592C5.77203 5.67146 6.27974 4.86014 6.93346 4.17438C7.58719 3.48863 8.37331 2.94272 9.24422 2.56972C10.1151 2.19672 11.0527 2.00439 12.0001 2.00439C12.9475 2.00439 13.8851 2.19672 14.756 2.56972C15.6269 2.94272 16.413 3.48863 17.0668 4.17438C17.7205 4.86014 18.2282 5.67146 18.5592 6.5592C18.8901 7.44694 19.0374 8.39262 18.9921 9.33896C20.3288 9.80844 21.4563 10.7358 22.1749 11.9568C22.8935 13.1778 23.1568 14.6137 22.9182 16.0103C22.6796 17.4068 21.9546 18.6739 20.8714 19.5871C19.7883 20.5004 18.4169 21.0009 17.0001 21ZM17.0001 8.99996C17.0004 8.33297 16.8672 7.67266 16.6083 7.05793C16.3495 6.4432 15.9703 5.88646 15.4931 5.4205C15.0158 4.95454 14.4502 4.58878 13.8294 4.34475C13.2087 4.10072 12.5454 3.98336 11.8786 3.99957C11.2118 4.01578 10.555 4.16525 9.94681 4.43916C9.33865 4.71307 8.79144 5.10589 8.33741 5.59449C7.88337 6.08309 7.53168 6.6576 7.30304 7.28418C7.07441 7.91077 6.97345 8.57677 7.00612 9.24296L7.07612 10.731L5.67212 11.225C4.78262 11.5395 4.03287 12.1583 3.55522 12.9719C3.07756 13.7855 2.90273 14.7418 3.06158 15.6718C3.22043 16.6018 3.70275 17.4458 4.42339 18.0547C5.14402 18.6637 6.05664 18.9985 7.00012 19H17.0001C17.7365 18.9997 18.4584 18.7962 19.0865 18.4119C19.7146 18.0276 20.2244 17.4774 20.5598 16.8218C20.8952 16.1663 21.0431 15.4309 20.9873 14.6967C20.9315 13.9625 20.6741 13.2579 20.2434 12.6606C19.8128 12.0633 19.2257 11.5965 18.5467 11.3116C17.8677 11.0266 17.1232 10.9347 16.3953 11.0457C15.6674 11.1568 14.9843 11.4667 14.4212 11.9412C13.8582 12.4157 13.437 13.0364 13.2041 13.735L11.3061 13.102C11.7049 11.9073 12.4694 10.8684 13.4913 10.1323C14.5132 9.3961 15.7407 8.99997 17.0001 8.99996Z"
        fill="currentColor"
      />
    </Icon>
  )
}
