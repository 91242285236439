import { Icon } from "@chakra-ui/react"
import React from "react"

export function EmojiSmile(props) {
  return (
    <Icon viewBox="0 0 18 18" width="18px" height="18px" {...props}>
      <path
        d="M9.00008 17.3334C4.39758 17.3334 0.666748 13.6025 0.666748 9.00002C0.666748 4.39752 4.39758 0.666687 9.00008 0.666687C13.6026 0.666687 17.3334 4.39752 17.3334 9.00002C17.3334 13.6025 13.6026 17.3334 9.00008 17.3334ZM9.00008 15.6667C10.7682 15.6667 12.4639 14.9643 13.7141 13.7141C14.9644 12.4638 15.6667 10.7681 15.6667 9.00002C15.6667 7.23191 14.9644 5.53622 13.7141 4.28598C12.4639 3.03573 10.7682 2.33335 9.00008 2.33335C7.23197 2.33335 5.53628 3.03573 4.28604 4.28598C3.03579 5.53622 2.33341 7.23191 2.33341 9.00002C2.33341 10.7681 3.03579 12.4638 4.28604 13.7141C5.53628 14.9643 7.23197 15.6667 9.00008 15.6667ZM5.66675 9.83335H12.3334C12.3334 10.7174 11.9822 11.5653 11.3571 12.1904C10.732 12.8155 9.88414 13.1667 9.00008 13.1667C8.11603 13.1667 7.26818 12.8155 6.64306 12.1904C6.01794 11.5653 5.66675 10.7174 5.66675 9.83335ZM5.66675 8.16669C5.33523 8.16669 5.01728 8.03499 4.78286 7.80057C4.54844 7.56615 4.41675 7.24821 4.41675 6.91669C4.41675 6.58517 4.54844 6.26722 4.78286 6.0328C5.01728 5.79838 5.33523 5.66669 5.66675 5.66669C5.99827 5.66669 6.31621 5.79838 6.55063 6.0328C6.78505 6.26722 6.91675 6.58517 6.91675 6.91669C6.91675 7.24821 6.78505 7.56615 6.55063 7.80057C6.31621 8.03499 5.99827 8.16669 5.66675 8.16669ZM12.3334 8.16669C12.0019 8.16669 11.684 8.03499 11.4495 7.80057C11.2151 7.56615 11.0834 7.24821 11.0834 6.91669C11.0834 6.58517 11.2151 6.26722 11.4495 6.0328C11.684 5.79838 12.0019 5.66669 12.3334 5.66669C12.6649 5.66669 12.9829 5.79838 13.2173 6.0328C13.4517 6.26722 13.5834 6.58517 13.5834 6.91669C13.5834 7.24821 13.4517 7.56615 13.2173 7.80057C12.9829 8.03499 12.6649 8.16669 12.3334 8.16669Z"
        fill="currentColor"
      />
    </Icon>
  )
}
