import { Icon } from "@chakra-ui/react"
import React from "react"

export function AddFill(props) {
  return (
    <Icon viewBox="0 0 14 12" {...props}>
      <path d="M5.65221 5.16699V0.166992H7.53221V5.16699H13.1722V6.83366H7.53221V11.8337H5.65221V6.83366H0.012207V5.16699H5.65221Z" fill="currentColor" />
    </Icon>
  )
}
