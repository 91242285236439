import { Icon } from "@chakra-ui/react"
import React from "react"

export function Minus(props) {
  return (
    <Icon width="10px" height="2px" viewBox="0 0 10 2" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.333496 0.333496H9.66683V1.66683H0.333496V0.333496Z" fill="currentColor" />
    </Icon>
  )
}
