import { Icon } from "@chakra-ui/react"
import React from "react"

export const BarberGuy = (props) => {
  return (
    <Icon viewBox="0 0 34 39" {...props}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.2361 31.1049C20.2619 31.1156 20.299 31.1302 20.3471 31.1478L20.372 31.1569C20.5212 31.2108 20.6972 31.2651 20.8982 31.3161C21.4265 31.4503 22.0238 31.5362 22.6794 31.5501C22.9029 31.5548 23.0758 31.7264 23.0572 31.925C23.0512 31.9889 23.0351 32.0905 23.0037 32.218C22.9517 32.429 22.8737 32.6405 22.763 32.8411L22.7427 32.877C22.3939 33.483 21.8109 33.8527 20.987 33.8251L20.9603 33.8238L20.9805 33.8231C20.0295 33.8659 19.5704 33.3003 19.5209 32.457C19.5049 32.185 19.5308 31.9007 19.5836 31.6186C19.5941 31.5628 19.6051 31.5101 19.6164 31.4601L19.6335 31.3876L19.6477 31.3321C19.6967 31.1136 19.9598 30.9944 20.1849 31.083L20.2361 31.1049ZM20.3253 31.8811L20.3164 31.9454C20.2957 32.1096 20.2878 32.2707 20.2966 32.4212C20.3265 32.9298 20.5065 33.1515 20.9412 33.132L20.9623 33.1315L20.9834 33.1321C21.5046 33.1592 21.8401 32.9446 22.0658 32.5358L22.095 32.4804C22.1326 32.4058 22.1649 32.3282 22.1921 32.2492L22.201 32.2219L22.1023 32.2145C21.5933 32.1735 21.1196 32.0928 20.6853 31.9825C20.5838 31.9568 20.4875 31.9301 20.3965 31.9029L20.3253 31.8811Z"
          fill="#000091"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.94334 26.7143C4.94334 26.7143 1.01992 28.1685 3.11759 31.0768C5.21526 33.9851 8.77612 30.6267 8.77612 30.6267L6.2123 26.345L4.94334 26.7143Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5557 26.182L9.11952 30.4637C9.19814 30.595 9.17383 30.7563 9.05915 30.8645L8.99541 30.9228C8.93274 30.9786 8.84651 31.0522 8.73902 31.1389C8.49018 31.3397 8.21281 31.5402 7.91283 31.7278C7.0024 32.2974 6.08006 32.6334 5.18639 32.607C4.24493 32.5792 3.42528 32.1427 2.7915 31.264C1.59533 29.6056 2.03943 28.1907 3.47423 27.1271C3.76154 26.9142 4.06769 26.7326 4.37397 26.5813L4.45095 26.5438C4.59995 26.4726 4.71852 26.4229 4.79487 26.3946C4.80425 26.3911 4.81378 26.388 4.82344 26.3852L6.09241 26.0159C6.2716 25.9637 6.46694 26.0338 6.5557 26.182ZM6.02443 26.764L5.08229 27.0382L5.02446 27.0617L5.00642 27.0692C4.93004 27.1016 4.8442 27.1408 4.75132 27.1867C4.48507 27.3182 4.21869 27.4762 3.97164 27.6593C2.81286 28.5183 2.48618 29.5591 3.44566 30.8893C3.94476 31.5813 4.53311 31.8947 5.21273 31.9147C5.90486 31.9351 6.68181 31.6521 7.46712 31.1608C7.73907 30.9907 7.99193 30.8079 8.21829 30.6253L8.29767 30.5603L6.02443 26.764Z"
          fill="#000091"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8061 12.819C13.0233 12.2881 21.1032 11.6072 22.903 14.504C26.0236 19.5358 27.7846 25.6871 28.3026 28.5955C28.8205 31.5038 14.616 35.0584 13.7355 35.093C12.855 35.1276 11.5731 34.6429 11.5731 34.6429L6.09582 27.1528C6.10876 27.1528 0.981136 17.5739 11.8061 12.819Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.16 12.03C20.1067 12.1843 22.313 12.8946 23.2171 14.2934L23.2441 14.336C25.9055 18.6274 27.9524 24.414 28.6874 28.5408C28.959 30.0662 26.3667 31.5961 21.8246 33.1953L21.6863 33.2438C18.6555 34.3027 14.4767 35.4101 13.7537 35.4385C13.5243 35.4475 13.2697 35.4275 12.9925 35.3832C12.7279 35.3409 12.4498 35.2777 12.1658 35.1991C12.0104 35.1561 11.8609 35.1106 11.7204 35.0646L11.6372 35.037C11.5378 35.0035 11.4643 34.9771 11.4225 34.9612C11.3514 34.9344 11.2909 34.8892 11.2487 34.8316L5.77149 27.3415L5.76006 27.3249C5.75284 27.3138 5.7464 27.3025 5.74074 27.2911L5.73165 27.2711L5.68973 27.1853C5.64578 27.0938 5.59928 26.9928 5.54887 26.8774C5.40258 26.5427 5.25784 26.1663 5.12265 25.7505C4.35259 23.3819 4.22114 20.9005 5.13985 18.5641C6.1188 16.0746 8.22375 14.0066 11.6355 12.508C12.4315 12.1608 14.9571 11.9147 17.16 12.03ZM22.6445 14.8006L22.5648 14.6713C21.8463 13.515 19.8484 12.8645 17.1149 12.7214C15.034 12.6124 12.6263 12.847 11.9784 13.1296C8.74728 14.5489 6.78402 16.4777 5.87349 18.7932C5.01623 20.9733 5.14032 23.3156 5.86932 25.5578C5.98709 25.9201 6.11225 26.2502 6.23874 26.5463L6.27326 26.6262C6.31256 26.7161 6.34915 26.7964 6.3838 26.8696L6.44116 26.9883L11.8335 34.3621L11.9124 34.3893C12.0626 34.4399 12.2261 34.4906 12.3967 34.5379C12.6521 34.6086 12.9001 34.6649 13.13 34.7017C13.3549 34.7376 13.5544 34.7533 13.7199 34.7468C14.3296 34.7228 18.4507 33.6307 21.4032 32.5992L21.6528 32.5113C25.6954 31.0759 28.0989 29.651 27.9205 28.6494C27.2062 24.6385 25.2198 19.0025 22.6445 14.8006Z"
          fill="#000091"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.37772 26.6335C8.29707 26.9913 11.5601 29.7381 12.6866 31.1576C12.6866 31.1576 16.4806 28.2954 22.7218 26.0103C22.7218 26.0103 26.1661 24.8678 27.694 24.7524C27.694 24.7524 29.8564 30.419 28.3544 34.2737C26.8523 38.1283 20.637 39.3863 17.115 38.613C13.593 37.8513 12.7773 37.182 11.3141 35.5547C9.86384 33.9274 5.12467 27.603 5.12467 27.603L7.37772 26.6335Z"
          fill="#968BB4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.127 24.9468C17.9382 24.5198 18.0332 24.0866 18.3393 23.9791C18.6453 23.8716 19.0465 24.1305 19.2353 24.5574C19.4241 24.9844 19.3291 25.4176 19.023 25.5251C18.717 25.6327 18.3158 25.3737 18.127 24.9468Z"
          fill="#000091"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2778 23.6269C22.1669 23.4632 21.9282 23.4106 21.7445 23.5093C21.5676 23.6045 21.5063 23.8053 21.601 23.9663L21.6126 23.9847L24.138 27.7147L22.443 28.345C22.2521 28.416 22.1583 28.607 22.2259 28.7788L22.2341 28.7978C22.3137 28.968 22.5281 29.0516 22.7208 28.9914L22.7422 28.9841L24.8528 28.1993C25.0645 28.1206 25.1525 27.8975 25.0474 27.7191L25.0358 27.7009L22.2778 23.6269Z"
          fill="#000091"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5345 23.5466C23.3457 23.1197 23.4407 22.6864 23.7468 22.5789C24.0528 22.4714 24.4539 22.7303 24.6428 23.1573C24.8316 23.5842 24.7365 24.0174 24.4305 24.125C24.1245 24.2325 23.7233 23.9735 23.5345 23.5466Z"
          fill="#000091"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3901 20.4962C17.3419 20.3099 17.1333 20.1937 16.9243 20.2367C16.1977 20.3861 15.6291 20.7122 15.207 21.1593C14.9983 21.3805 14.8391 21.6167 14.7222 21.8535C14.6506 21.9986 14.608 22.1153 14.5879 22.19C14.538 22.3759 14.6667 22.5628 14.8753 22.6073C15.0765 22.6502 15.2786 22.5451 15.3376 22.3708L15.3487 22.3346C15.3514 22.3262 15.3553 22.3151 15.3603 22.3013C15.3786 22.2513 15.4027 22.1944 15.4333 22.1324C15.522 21.9528 15.6434 21.7727 15.8015 21.6051C16.1189 21.2689 16.5428 21.0258 17.099 20.9114C17.308 20.8684 17.4384 20.6825 17.3901 20.4962Z"
          fill="#000091"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2898 18.4759C22.8366 18.3953 22.3748 18.4804 21.9295 18.6659C21.7361 18.7464 21.5873 18.8278 21.4966 18.8868C21.3232 18.9994 21.2851 19.216 21.4115 19.3705C21.533 19.5191 21.7624 19.5562 21.9339 19.4587L21.9863 19.427C21.9979 19.4203 22.0121 19.4123 22.0288 19.4031C22.0968 19.3659 22.1741 19.3283 22.2583 19.2932C22.5673 19.1646 22.8739 19.1081 23.1377 19.155C23.3431 19.1915 23.5243 19.2906 23.6867 19.4736C23.8198 19.6236 24.0641 19.6491 24.2323 19.5305C24.4006 19.4119 24.4292 19.1942 24.2961 19.0442C24.0195 18.7324 23.6763 18.5447 23.2898 18.4759Z"
          fill="#000091"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.10945 28.4453C6.10945 28.4453 3.11833 26.5411 1.22784 24.129C-0.546116 21.867 -0.403679 18.7855 1.69399 16.7428C3.45499 15.0347 5.56561 14.7578 5.56561 14.7578C5.56561 14.7578 2.09539 11.6763 4.7628 7.6139C5.6951 6.18283 7.27482 5.15568 9.08762 4.78637C13.516 3.87464 15.8468 6.87528 15.8468 6.87528C15.8468 6.87528 18.307 -1.93046 29.2874 0.389268C30.401 0.620087 31.8642 1.64723 32.6411 2.39739C37.3673 6.89836 28.6659 15.5425 23.344 17.3083C17.4912 19.2472 14.0858 18.7278 11.6644 18.1162C13.3736 18.3585 15.2252 26.6103 6.10945 28.4453Z"
          fill="#000091"
        />
      </svg>
    </Icon>
  )
}
