import { Icon } from "@chakra-ui/react"

/**
 * @description Copy icon.
 * @param {Object} props
 * @return {JSX.Element}
 */
export const Copy = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      d="M2.66667 2.99967V0.999674C2.66667 0.822863 2.7369 0.653294 2.86193 0.52827C2.98695 0.403246 3.15652 0.333008 3.33333 0.333008H11.3333C11.5101 0.333008 11.6797 0.403246 11.8047 0.52827C11.9298 0.653294 12 0.822863 12 0.999674V10.333C12 10.5098 11.9298 10.6794 11.8047 10.8044C11.6797 10.9294 11.5101 10.9997 11.3333 10.9997H9.33333V12.9997C9.33333 13.3677 9.03333 13.6663 8.662 13.6663H0.671333C0.583418 13.6669 0.496265 13.65 0.414882 13.6168C0.333498 13.5835 0.259489 13.5345 0.197106 13.4725C0.134723 13.4106 0.0851953 13.3369 0.0513695 13.2558C0.0175437 13.1746 8.62495e-05 13.0876 0 12.9997L0.00200001 3.66634C0.00200001 3.29834 0.302 2.99967 0.673334 2.99967H2.66667ZM1.33533 4.33301L1.33333 12.333H8V4.33301H1.33533ZM4 2.99967H9.33333V9.66634H10.6667V1.66634H4V2.99967Z"
      fill="currentColor"
    />
  </Icon>
)
