import { Icon } from "@chakra-ui/react"
import React from "react"

export function Star(props) {
  return (
    <Icon viewBox="0 0 18 15" width="18px" height="15px" {...props}>
      <path
        d="M9.07688 12.1735L4.01319 14.8055L5.14396 9.52016L0.882935 5.8615L6.64591 5.22683L9.07688 0.333496L11.5079 5.22683L17.2708 5.8615L13.0098 9.52016L14.1406 14.8055L9.07688 12.1735Z"
        fill="currentColor"
      />
    </Icon>
  )
}
