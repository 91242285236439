import { Icon } from "@chakra-ui/react"
import React from "react"

export function ThumbDown(props) {
  return (
    <Icon viewBox="0 0 16 14" {...props}>
      <path
        d="M6.2665 8.66667H1.99984C1.64622 8.66667 1.30708 8.52619 1.05703 8.27614C0.80698 8.02609 0.666505 7.68696 0.666505 7.33333V5.93067C0.666325 5.75643 0.700299 5.58384 0.766505 5.42267L2.8305 0.413333C2.88069 0.291169 2.96603 0.186663 3.07571 0.113075C3.18538 0.0394861 3.31443 0.000130314 3.4465 0H14.6665C14.8433 0 15.0129 0.0702379 15.1379 0.195262C15.2629 0.320286 15.3332 0.489856 15.3332 0.666667V7.33333C15.3332 7.51014 15.2629 7.67971 15.1379 7.80474C15.0129 7.92976 14.8433 8 14.6665 8H12.3452C12.2384 7.99997 12.1333 8.02557 12.0385 8.07464C11.9437 8.12372 11.8621 8.19483 11.8005 8.282L8.16517 13.4327C8.11922 13.4978 8.05145 13.5443 7.97415 13.5637C7.89685 13.5832 7.81514 13.5743 7.74384 13.5387L6.5345 12.9333C6.19417 12.7632 5.92228 12.4818 5.76402 12.1358C5.60575 11.7898 5.57064 11.4001 5.6645 11.0313L6.2665 8.66667ZM11.3332 6.94133V1.33333H3.89317L1.99984 5.93067V7.33333H6.2665C6.46957 7.33336 6.66995 7.37977 6.85235 7.46903C7.03475 7.55828 7.19436 7.68802 7.31899 7.84834C7.44362 8.00867 7.52998 8.19534 7.57149 8.39412C7.613 8.5929 7.60856 8.79853 7.55851 8.99533L6.9565 11.3607C6.93766 11.4344 6.94465 11.5125 6.97631 11.5817C7.00797 11.651 7.06238 11.7073 7.1305 11.7413L7.57117 11.9613L10.7112 7.51333C10.8778 7.27733 11.0912 7.084 11.3332 6.94133ZM12.6665 6.66667H13.9998V1.33333H12.6665V6.66667Z"
        fill="currentColor"
      />
    </Icon>
  )
}
