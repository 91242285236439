import { Icon } from "@chakra-ui/react"
import React from "react"

export function RejectIcon(props) {
  return (
    <Icon viewBox="0 0 14 14" width="14px" height="14px" {...props}>
      <path
        d="M7.00004 13.6667C3.31804 13.6667 0.333374 10.682 0.333374 7.00004C0.333374 3.31804 3.31804 0.333374 7.00004 0.333374C10.682 0.333374 13.6667 3.31804 13.6667 7.00004C13.6667 10.682 10.682 13.6667 7.00004 13.6667ZM7.00004 12.3334C8.41453 12.3334 9.77108 11.7715 10.7713 10.7713C11.7715 9.77108 12.3334 8.41453 12.3334 7.00004C12.3334 5.58555 11.7715 4.229 10.7713 3.2288C9.77108 2.22861 8.41453 1.66671 7.00004 1.66671C5.58555 1.66671 4.229 2.22861 3.2288 3.2288C2.22861 4.229 1.66671 5.58555 1.66671 7.00004C1.66671 8.41453 2.22861 9.77108 3.2288 10.7713C4.229 11.7715 5.58555 12.3334 7.00004 12.3334ZM10.2607 4.68204L4.68204 10.2607C4.31762 10.001 3.99904 9.68246 3.73937 9.31804L9.31804 3.73937C9.68246 3.99904 10.001 4.31762 10.2607 4.68204Z"
        fill="currentColor"
      />
    </Icon>
  )
}
