import { Icon } from "@chakra-ui/react"
import React from "react"

export function FolderOpenLine(props) {
  return (
    <Icon viewBox="0 0 21 18" {...props}>
      <path
        d="M1 18C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H8.414L10.414 2H18C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V6H17V4H9.586L7.586 2H2V13.998L3.5 8H20.5L18.19 17.243C18.1358 17.4592 18.011 17.6512 17.8352 17.7883C17.6595 17.9255 17.4429 18 17.22 18H1ZM17.938 10H5.062L3.562 16H16.438L17.938 10Z"
        fill="currentColor"
      />
    </Icon>
  )
}
