import { Icon } from "@chakra-ui/react"
import React from "react"

export function InformationLine(props) {
  return (
    <Icon viewBox="0 0 14 14" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99967 13.6663C3.31767 13.6663 0.333008 10.6817 0.333008 6.99967C0.333008 3.31767 3.31767 0.333008 6.99967 0.333008C10.6817 0.333008 13.6663 3.31767 13.6663 6.99967C13.6663 10.6817 10.6817 13.6663 6.99967 13.6663ZM6.99967 12.333C9.94519 12.333 12.333 9.94519 12.333 6.99967C12.333 4.05416 9.94519 1.66634 6.99967 1.66634C4.05416 1.66634 1.66634 4.05416 1.66634 6.99967C1.66634 9.94519 4.05416 12.333 6.99967 12.333ZM6.33301 3.66634H7.66634V4.99967H6.33301V3.66634ZM6.33301 6.33301H7.66634V10.333H6.33301V6.33301Z"
        fill="currentColor"
      />
    </Icon>
  )
}
