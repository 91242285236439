import { Icon } from "@chakra-ui/react"
import React from "react"

export function CheckLine(props) {
  return (
    <Icon viewBox="0 0 24 17" {...props}>
      <path d="M9.33333 13.2294L21.5893 0.972046L23.476 2.85738L9.33333 17L0.848 8.51471L2.73333 6.62938L9.33333 13.2294Z" fill="currentColor" />
    </Icon>
  )
}
