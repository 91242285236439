import { Icon } from "@chakra-ui/react"
import React from "react"

export function LinkLine(props) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1934 15.3644L16.7794 13.9484L18.1934 12.5344C19.4657 11.2731 19.966 9.4275 19.5049 7.69633C19.0437 5.96516 17.6916 4.61302 15.9604 4.15186C14.2292 3.6907 12.3836 4.19101 11.1224 5.46336L9.70836 6.87836L8.29336 5.46436L9.70936 4.05036C12.4432 1.31655 16.8755 1.31655 19.6094 4.05036C22.3432 6.78417 22.3432 11.2165 19.6094 13.9504L18.1944 15.3644H18.1934ZM15.3654 18.1924L13.9504 19.6064C11.2165 22.3402 6.78417 22.3402 4.05036 19.6064C1.31655 16.8725 1.31655 12.4402 4.05036 9.70636L5.46536 8.29236L6.87936 9.70836L5.46536 11.1224C4.19301 12.3836 3.6927 14.2292 4.15386 15.9604C4.61502 17.6916 5.96716 19.0437 7.69833 19.5049C9.4295 19.966 11.2751 19.4657 12.5364 18.1934L13.9504 16.7794L15.3654 18.1934V18.1924ZM14.6574 7.58536L16.0724 9.00036L9.00136 16.0704L7.58636 14.6564L14.6574 7.58636V7.58536Z"
        fill="currentColor"
      />
    </Icon>
  )
}
