import { Icon } from "@chakra-ui/react"
import React from "react"

export function ThumbUp(props) {
  return (
    <Icon viewBox="0 0 16 14" {...props}>
      <path
        d="M9.73317 5.33359H13.9998C14.3535 5.33359 14.6926 5.47407 14.9426 5.72412C15.1927 5.97417 15.3332 6.31331 15.3332 6.66693V8.06959C15.3334 8.24383 15.2994 8.41642 15.2332 8.57759L13.1698 13.5876C13.1195 13.7097 13.034 13.8142 12.9242 13.8876C12.8144 13.9611 12.6853 14.0003 12.5532 14.0003H1.33317C1.15636 14.0003 0.98679 13.93 0.861766 13.805C0.736742 13.68 0.666504 13.5104 0.666504 13.3336V6.66693C0.666504 6.49012 0.736742 6.32055 0.861766 6.19552C0.98679 6.0705 1.15636 6.00026 1.33317 6.00026H3.6545C3.76123 6.00029 3.86641 5.97469 3.96119 5.92562C4.05597 5.87654 4.13759 5.80543 4.19917 5.71826L7.8345 0.566928C7.88046 0.501803 7.94823 0.455302 8.02553 0.435855C8.10283 0.416408 8.18453 0.425303 8.25584 0.460928L9.46517 1.06559C9.80551 1.2357 10.0774 1.51714 10.2357 1.86314C10.3939 2.20914 10.429 2.59888 10.3352 2.96759L9.73317 5.33359ZM4.6665 7.05893V12.6669H12.1065L13.9998 8.06959V6.66693H9.73317C9.5301 6.6669 9.32973 6.62049 9.14732 6.53123C8.96492 6.44198 8.80532 6.31224 8.68069 6.15192C8.55606 5.9916 8.46969 5.80492 8.42818 5.60614C8.38667 5.40736 8.39111 5.20173 8.44117 5.00493L9.04317 2.63959C9.06201 2.56581 9.05502 2.4878 9.02337 2.41855C8.99171 2.34929 8.93729 2.29296 8.86917 2.25893L8.4285 2.03893L5.2885 6.48693C5.12184 6.72293 4.9085 6.91626 4.6665 7.05893ZM3.33317 7.33359H1.99984V12.6669H3.33317V7.33359Z"
        fill="currentColor"
      />
    </Icon>
  )
}
