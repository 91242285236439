import { Icon } from "@chakra-ui/react"
import React from "react"

export function AddBoxFill(props) {
  return (
    <Icon width="16" height="15" viewBox="0 0 16 15" {...props}>
      <path
        d="M1.33333 0H14.6667C14.8877 0 15.0996 0.0877973 15.2559 0.244078C15.4122 0.400358 15.5 0.61232 15.5 0.833333V14.1667C15.5 14.3877 15.4122 14.5996 15.2559 14.7559C15.0996 14.9122 14.8877 15 14.6667 15H1.33333C1.11232 15 0.900358 14.9122 0.744078 14.7559C0.587797 14.5996 0.5 14.3877 0.5 14.1667V0.833333C0.5 0.61232 0.587797 0.400358 0.744078 0.244078C0.900358 0.0877973 1.11232 0 1.33333 0ZM7.16667 6.66667H3.83333V8.33333H7.16667V11.6667H8.83333V8.33333H12.1667V6.66667H8.83333V3.33333H7.16667V6.66667Z"
        fill="currentColor"
      />
    </Icon>
  )
}
