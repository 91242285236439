import { Icon } from "@chakra-ui/react"
import React from "react"

export function Repeat(props) {
  return (
    <Icon viewBox="0 0 19 19" {...props}>
      <path
        d="M6.33325 15.8334V17.3629C6.33337 17.4381 6.31203 17.5119 6.27171 17.5755C6.2314 17.6391 6.17379 17.6898 6.10565 17.7218C6.0375 17.7538 5.96165 17.7657 5.88698 17.7561C5.81231 17.7465 5.74193 17.7158 5.68409 17.6677L2.42242 14.9499C2.35986 14.8977 2.31492 14.8275 2.29373 14.7488C2.27253 14.6702 2.27612 14.5869 2.304 14.5104C2.33188 14.4338 2.38269 14.3678 2.4495 14.3212C2.51632 14.2746 2.59588 14.2497 2.67734 14.25H14.2499C14.6698 14.25 15.0726 14.0832 15.3695 13.7863C15.6664 13.4894 15.8333 13.0866 15.8333 12.6667V6.33337H17.4166V12.6667C17.4166 13.5066 17.083 14.312 16.4891 14.9059C15.8952 15.4997 15.0898 15.8334 14.2499 15.8334H6.33325ZM12.6666 3.1667V1.6372C12.6665 1.56192 12.6878 1.48816 12.7281 1.42459C12.7684 1.36101 12.826 1.31024 12.8942 1.27825C12.9623 1.24626 13.0382 1.23436 13.1129 1.24395C13.1875 1.25354 13.2579 1.28423 13.3158 1.33241L16.5774 4.0502C16.6399 4.10232 16.6848 4.17245 16.7061 4.25102C16.7273 4.3296 16.7238 4.4128 16.696 4.4893C16.6682 4.56581 16.6175 4.63188 16.5508 4.67854C16.4841 4.72519 16.4047 4.75016 16.3233 4.75003H4.74992C4.32999 4.75003 3.92727 4.91685 3.63033 5.21378C3.3334 5.51071 3.16659 5.91344 3.16659 6.33337V12.6667H1.58325V6.33337C1.58325 5.49351 1.91688 4.68806 2.51075 4.09419C3.10461 3.50033 3.91007 3.1667 4.74992 3.1667H12.6666Z"
        fill="currentColor"
      />
    </Icon>
  )
}
