import { Icon } from "@chakra-ui/react"
import React from "react"

export function ArrowLeft(props) {
  return (
    <Icon viewBox="0 0 12 12" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.21863 5.33336H11.3333V6.6667H3.21863L6.79463 10.2427L5.85196 11.1854L0.666626 6.00003L5.85196 0.814697L6.79463 1.75736L3.21863 5.33336Z"
        fill="currentColor"
      />
    </Icon>
  )
}
