import { Icon } from "@chakra-ui/react"
import React from "react"

export function Edit2Fill(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M5.7025 13.8334H15.5V15.5001H0.5V11.9642L8.75 3.71424L12.285 7.2509L5.70167 13.8334H5.7025ZM9.9275 2.53674L11.6958 0.768403C11.8521 0.612177 12.064 0.524414 12.285 0.524414C12.506 0.524414 12.7179 0.612177 12.8742 0.768403L15.2317 3.1259C15.3879 3.28218 15.4757 3.4941 15.4757 3.71507C15.4757 3.93604 15.3879 4.14796 15.2317 4.30424L13.4633 6.07174L9.92833 2.53674H9.9275Z"
        fill="currentColor"
      />
    </Icon>
  )
}
