import { Icon } from "@chakra-ui/react"
import React from "react"

export function MapPin2Fill(props) {
  return (
    <Icon viewBox="0 0 12 15" {...props}>
      <path
        fill="currentColor"
        d="M10.2427 10.5756L6 14.8183L1.75734 10.5756C0.918228 9.7365 0.346791 8.66741 0.115286 7.50352C-0.11622 6.33964 0.00260456 5.13324 0.456732 4.03689C0.91086 2.94054 1.6799 2.00347 2.66659 1.34418C3.65328 0.6849 4.81332 0.333008 6 0.333008C7.18669 0.333008 8.34672 0.6849 9.33342 1.34418C10.3201 2.00347 11.0891 2.94054 11.5433 4.03689C11.9974 5.13324 12.1162 6.33964 11.8847 7.50352C11.6532 8.66741 11.0818 9.7365 10.2427 10.5756ZM6 7.66629C6.35362 7.66629 6.69276 7.52581 6.94281 7.27576C7.19286 7.02572 7.33334 6.68658 7.33334 6.33296C7.33334 5.97933 7.19286 5.64019 6.94281 5.39015C6.69276 5.1401 6.35362 4.99962 6 4.99962C5.64638 4.99962 5.30724 5.1401 5.05719 5.39015C4.80715 5.64019 4.66667 5.97933 4.66667 6.33296C4.66667 6.68658 4.80715 7.02572 5.05719 7.27576C5.30724 7.52581 5.64638 7.66629 6 7.66629Z"
      />
    </Icon>
  )
}
