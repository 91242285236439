import { Icon } from "@chakra-ui/react"
import React from "react"

export function Tick(props) {
  return (
    <Icon viewBox="0 0 18 18" width="18px" height="18px" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00008 17.3334C4.39758 17.3334 0.666748 13.6025 0.666748 9.00002C0.666748 4.39752 4.39758 0.666687 9.00008 0.666687C13.6026 0.666687 17.3334 4.39752 17.3334 9.00002C17.3334 13.6025 13.6026 17.3334 9.00008 17.3334ZM9.00008 15.6667C12.682 15.6667 15.6667 12.6819 15.6667 9.00002C15.6667 5.31812 12.682 2.33335 9.00008 2.33335C5.31818 2.33335 2.33341 5.31812 2.33341 9.00002C2.33341 12.6819 5.31818 15.6667 9.00008 15.6667ZM8.16925 12.3334L4.63341 8.79752L5.81175 7.61919L8.16925 9.97669L12.8826 5.26252L14.0617 6.44085L8.16925 12.3334Z"
        fill="currentColor"
      />
    </Icon>
  )
}
