import { Icon } from "@chakra-ui/react"
import React from "react"

export function CloudSlashed(props) {
  return (
    <Icon viewBox="0 0 24 24" w="15px" h="15px" {...props}>
      <path
        d="M3.51512 2.10001L22.6071 21.192L21.1921 22.607L19.1781 20.592C18.4838 20.8624 17.7452 21.0008 17.0001 21H7.00012C5.58335 21.0009 4.21197 20.5004 3.12881 19.5872C2.04565 18.6739 1.32059 17.4069 1.08202 16.0103C0.843459 14.6138 1.10678 13.1779 1.82535 11.9569C2.54393 10.7358 3.6714 9.80849 5.00812 9.33901C4.96532 8.47016 5.08504 7.60095 5.36112 6.77601L2.10012 3.51401L3.51512 2.10001ZM7.00012 9.00001C7.00012 9.08101 7.00212 9.16301 7.00612 9.24301L7.07612 10.731L5.67212 11.225C4.78262 11.5396 4.03287 12.1583 3.55522 12.9719C3.07756 13.7856 2.90273 14.7418 3.06158 15.6718C3.22043 16.6018 3.70275 17.4458 4.42339 18.0548C5.14402 18.6637 6.05664 18.9985 7.00012 19H17.0001C17.1861 19 17.3691 18.987 17.5481 18.963L7.03012 8.44501C7.01012 8.62701 7.00012 8.81201 7.00012 9.00001ZM12.0001 2.00001C12.9479 1.99998 13.8859 2.19244 14.7571 2.56571C15.6283 2.93898 16.4147 3.48529 17.0684 4.17153C17.7222 4.85776 18.2298 5.66962 18.5605 6.55788C18.8912 7.44614 19.038 8.39231 18.9921 9.33901C19.8226 9.63156 20.5786 10.1032 21.2064 10.7206C21.8343 11.3379 22.3186 12.0859 22.6252 12.9113C22.9317 13.7368 23.0528 14.6195 22.9801 15.497C22.9073 16.3746 22.6424 17.2253 22.2041 17.989L20.7111 16.496C21.0054 15.7681 21.0789 14.9696 20.9224 14.2002C20.7659 13.4307 20.3863 12.7244 19.831 12.1692C19.2758 11.6141 18.5694 11.2347 17.7999 11.0783C17.0305 10.922 16.232 10.9956 15.5041 11.29L14.0101 9.79501C14.8911 9.29001 15.9111 9.00001 17.0001 9.00001C17.0001 8.08447 16.7487 7.18651 16.2734 6.40405C15.798 5.62158 15.1169 4.98466 14.3044 4.56272C13.4919 4.14078 12.5792 3.95003 11.6657 4.01127C10.7522 4.07251 9.87304 4.38339 9.12412 4.91001L7.69412 3.48001C8.9233 2.51854 10.4396 1.99739 12.0001 2.00001V2.00001Z"
        fill="currentColor"
      />
    </Icon>
  )
}
