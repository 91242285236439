import { Icon } from "@chakra-ui/react"
import React from "react"

export function Building(props) {
  return (
    <Icon viewBox="0 0 14 13" {...props}>
      <path
        d="M6.99992 3.16667V0.5H0.333252V12.5H13.6666V3.16667H6.99992ZM2.99992 11.1667H1.66659V9.83333H2.99992V11.1667ZM2.99992 8.5H1.66659V7.16667H2.99992V8.5ZM2.99992 5.83333H1.66659V4.5H2.99992V5.83333ZM2.99992 3.16667H1.66659V1.83333H2.99992V3.16667ZM5.66659 11.1667H4.33325V9.83333H5.66659V11.1667ZM5.66659 8.5H4.33325V7.16667H5.66659V8.5ZM5.66659 5.83333H4.33325V4.5H5.66659V5.83333ZM5.66659 3.16667H4.33325V1.83333H5.66659V3.16667ZM12.3333 11.1667H6.99992V9.83333H8.33325V8.5H6.99992V7.16667H8.33325V5.83333H6.99992V4.5H12.3333V11.1667ZM10.9999 5.83333H9.66659V7.16667H10.9999V5.83333ZM10.9999 8.5H9.66659V9.83333H10.9999V8.5Z"
        fill="currentColor"
      />
    </Icon>
  )
}
