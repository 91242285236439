import { Icon } from "@chakra-ui/react"
import React from "react"

export function Clock(props) {
  return (
    <Icon viewBox="0 0 18 21" {...props}>
      <path
        d="M14.6181 4.968L16.0711 3.515L17.4851 4.929L16.0321 6.382C17.4678 8.17917 18.161 10.4579 17.9691 12.7501C17.7773 15.0424 16.7151 17.1742 15.0005 18.7077C13.286 20.2412 11.0494 21.0601 8.75004 20.9961C6.45067 20.9321 4.26307 19.9901 2.63653 18.3635C1.00999 16.737 0.0679877 14.5494 0.00397711 12.25C-0.0600335 9.95063 0.75881 7.71402 2.29234 5.99951C3.82587 4.285 5.95767 3.22275 8.24993 3.03092C10.5422 2.83909 12.8209 3.53223 14.6181 4.968ZM9.00006 19C9.91931 19 10.8296 18.8189 11.6788 18.4672C12.5281 18.1154 13.2998 17.5998 13.9498 16.9497C14.5998 16.2997 15.1154 15.5281 15.4672 14.6788C15.819 13.8295 16.0001 12.9193 16.0001 12C16.0001 11.0807 15.819 10.1705 15.4672 9.32122C15.1154 8.47194 14.5998 7.70026 13.9498 7.05025C13.2998 6.40024 12.5281 5.88463 11.6788 5.53284C10.8296 5.18106 9.91931 5 9.00006 5C7.14354 5 5.36307 5.7375 4.05031 7.05025C2.73756 8.36301 2.00006 10.1435 2.00006 12C2.00006 13.8565 2.73756 15.637 4.05031 16.9497C5.36307 18.2625 7.14354 19 9.00006 19ZM8.00006 7H10.0001V13H8.00006V7ZM5.00006 0H13.0001V2H5.00006V0Z"
        fill="currentColor"
      />
    </Icon>
  )
}
